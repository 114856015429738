import request from '@/utils/http';

/**
 * 获取全局配置信息
 * @param payload
 */
export function get_global_setting(payload) {
    return request({ url: '/setting/Index/getGlobalSetting', method: 'post', data: payload })
}

/**
 * 保存配置信息
 * @param payload
 */
export function save_setting(payload) {
    return request({ url: '/setting/Index/saveSetting', method: 'post', data: payload })
}